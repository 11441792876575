* {
  font-family: "Lato", "Helvetica Neue", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Lato", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.dashboard-element {
  background-size: cover; 
  background-position: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.dashboard-element:nth-child(odd){
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.dashboard-element h2{
  font-weight: 900;
  font-size: 3rem;
}

.modal-header {
  background-color: #323c43;
}

.modal-title {
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
}

.modal-header button.close span {
  color: #fff;
}

.modal-body {
  padding: 5rem;
  font-size: 1.2rem;
  color: #444;
  line-height: 1.6;
}

.modal-footer {
  background-color: #323c43;
}

.ReactTable {
  width: 100%; 
}
.wp-block-image img {
  max-width: 100%;
  height: auto;
}

.wp-content {
  text-align: center;
}

.wp-content p,
.wp-content table{
  text-align: left;
}

.pagination {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
